.shopping-card {
    margin: 0.5rem;
    padding: 0.5rem;
    width: 18rem;
    height: 22rem;
    min-width: 12rem;
    color: var(--mamab-font-color);
    background-color: #d8f6fd;
    margin-left: auto;
    margin-right: auto;
    .shopping-card-body {
        height: 4rem;
    }
    .shopping-card-footer {
        text-align: center;
        vertical-align: bottom;
        height: 6.5rem;
        .selected-div {
            input {
                margin-top: 0.5rem;
                width: 4rem;
                margin-left: 0.5rem;
                text-align: center;
            }
        }
    }
}


.session-body {
    padding-top: 5rem;
}