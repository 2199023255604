
    .grid-item {
        width: 45%;
        padding: 1px;
    } 

@media (min-width: 992px) { 
    .grid-item {
        width: 32%;
        padding: 1px;
    } 
}

@media (min-width: 1200px) { 
    .grid-item {
        width: 19%;
        padding: 1px;
    } 
}

.gallery-code-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 10vh;
}

.gallery-code-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50vh;
}

.code {
    margin: 0.5rem;
}

.gallery-button {
    width: 15rem;
    margin: 1rem;
}

.preview {
    width: 100%;
}