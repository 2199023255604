.main-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20vh;
    width: 100vw;
    .row {
        width: 100vw;
    }
}

.main-left {
    border-right: 1px solid #878d97;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 35vh;
    img {
        object-fit: cover;
        width: 70%;
        max-width: 600px;
        height: 20vh;
    }
    p {
        padding-right: 1rem;
    }
}

.main-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    img {
        object-fit: scale-down;
        width: 80%;
        max-width: 700px;
        height: 35vh;
        padding-left: 1rem;
    }
}
.main-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10vh;
}

.horizontal-separator {
    width: 85vw;
    height: 1px;
    background-color: #878d97;
    margin-left: auto;
    margin-right: auto;
}