@keyframes pan {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
  }

.bg-primary {
    background-color: #fdf6c4 !important;
}

.carousel-image {
    width: 100%;
    max-width: 1200px;
    height: 600px;
    background-position: center;
    margin-left: auto;
    margin-right: auto;
}