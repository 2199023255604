:root {
  --mamab-font-color: #45423c;
  --mamab-bold-element: #fdf6c4 ;
  --mamab-bold-element-hover: #d5d0a9 ;
  --mamab-sub-element: #d8f6fd;
  --mamab-bacgkround: #f9f9ef;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary {
  --bs-btn-bg: var(--mamab-bold-element);
  --bs-btn-hover-bg: var(--mamab-bold-element-hover);
  --bs-btn-color: var(--mamab-font-color);
}